<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from '@/components/management/Login.vue'

export default {
  name: 'LoginPage',

  components: {
    Login,
  },
}
</script>

<style scoped></style>
