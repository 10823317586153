var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('v-container',{staticClass:"projectDetails mx-auto"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Gallery',{attrs:{"id":1,"count":4,"videos":[4],"url":[
              _vm.getImageURL(1),
              _vm.getImageURL(2),
              _vm.getImageURL(3),
              _vm.getVideoURL() ]}})],1)],1),_c('v-row',{staticClass:"alignCenter"},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.project.title))])])],1),_c('v-row',{staticClass:"alignCenter"},[_c('v-col',[_vm._v("@ "+_vm._s(_vm.project.location)+" on "+_vm._s(_vm.project.date)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.project.description)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.project.shortStory)+" ")])],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-card',{staticClass:"projectTop"},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('Gallery',{attrs:{"count":4,"videos":[4],"url":[
              _vm.getImageURL(1),
              _vm.getImageURL(2),
              _vm.getImageURL(3),
              _vm.getVideoURL() ]}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-row',{staticClass:"alignCenter"},[_c('v-col',[_c('h1',[_vm._v(_vm._s(_vm.project.title))])])],1),_c('v-row',{staticClass:"alignCenter"},[_c('v-col',[_vm._v("@ "+_vm._s(_vm.project.location)+" on "+_vm._s(_vm.project.date)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.project.description)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.project.shortStory)+" ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('Specs',{attrs:{"project":_vm.project}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Log')],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Download')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }