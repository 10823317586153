<template>
  <v-card class="log">
    <v-card-title>
      <h1>History</h1>
    </v-card-title></v-card
  >
</template>

<script>
export default {}
</script>

<style scoped>
.log {
  height: 100%;
}

h1 {
  text-align: center;
  width: 100%;
}
</style>
